import './ProsDetailsHeadlineComponent.css';
import 'react-toastify/dist/ReactToastify.css';
import LabelCardComponent from '../LabelCardComponent/LabelCardComponent';
import axios from 'axios';
import { useEffect, useState } from 'react';

function ProsDetailsHeadlineComponent(props) {
  const [countPros, setCountPros] = useState(0);
  const [maxProsDate, setMaxProsDate] = useState("/");
  const [firstPerf, setFirstPerf] = useState("/");
  const [lastPerf, setLastPerf] = useState("/");
  const [subDate, setSubDate] = useState("/");
  useEffect(() => {
    if(props.site_pros_url==="undefined"){
      return;
    }
    (async () => {
      try {
          const resp = await axios.post("https://www.themoneytizer.com/pros_api/countDomainProspection", {site_pros_url: props.site_pros_url});
          const obj = await resp.data;
          if(obj.results!=null){
            setCountPros(obj.results.site_pros_total);
            setMaxProsDate(obj.results.site_pros_max_date);
          }
      } catch (error) {
          console.error(error);
      }
      
    })();

    (async () => {
      try {
          const resp = await axios.post("https://www.themoneytizer.com/pros_api/getProsDetails", {url: props.site_pros_url});
          const obj = await resp.data;
          if(obj.results!=null){
            if(obj.results.site_pros_date){setSubDate(obj.results.site_pros_date)};
            if(obj.results.cohorte_first_perf){setFirstPerf(obj.results.cohorte_first_perf)};
            if(obj.results.cohorte_last_perf){setLastPerf(obj.results.cohorte_last_perf)};
          }
      } catch (error) {
          console.error(error);
      }
    })();

  }, [props.site_pros_url])
  return (
    <div className={"ProsDetailsHeadline " + props.childClass}>
      <div className="FlexCol JustifyBetween">
        <p className="M0"><a className="ActionLink" target="blank" href={props.site_pros_url}>{props.site_pros_url}</a> has been prospected <b>{countPros}</b> times.<br/>
        The most recent prospection for this domain was on <b>{maxProsDate}</b>.
        </p>
        <div className="FlexRow MT10">
          <LabelCardComponent icon="fa-solid fa-sack-dollar" color="BPurple" text={"First perf : "+firstPerf}></LabelCardComponent>
          <LabelCardComponent childClass="ML10" icon="fa-solid fa-sack-dollar" color="BGreen" text={"Last perf : "+lastPerf}></LabelCardComponent>
          <LabelCardComponent childClass="ML10" icon="fa-solid fa-calendar-days" color="BRed" text={"Subscribed date : "+subDate}></LabelCardComponent>
        </div>
      </div>
    </div>
  );
}
  
export default ProsDetailsHeadlineComponent;
  