import './WarmListRowComponent.css';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function WarmListRowComponent(props) {
  const [hide, setHide] = useState(false);

  const navigate = useNavigate();

  const redirectToDetails = function(){
      let encodedUrl = btoa(props.site_pros_url);
      navigate('/pros_details/'+encodedUrl, { replace: false });
  }

  const removeFromWarmList = function(){
    (async () => {
        try {
          const resp = await axios.post("https://www.themoneytizer.com/pros_api/removeFromWarmList", {site_pros_id: props.site_pros_id});
          const obj = await resp.data;
          if(obj.results){
            setHide(true);
          }
        } catch (error) {
            console.error(error);
        }
      })();
  }

  const confirmWarmList = function(){
    confirmAlert({
        title: 'Confirmation required',
        message: 'Are you sure to do this ?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => removeFromWarmList()
          },
          {
            label: 'No'
          }
        ]
      });
  }

  return (
    <tr className={"WarmListRow TCenter " + props.childClass + (hide ? " Hide" : "")}>
        <td>{props.site_pros_date}</td>
        <td>{props.user_pros_name}</td>
        <td>{props.site_pros_com}</td>
        <td>{props.site_pros_url}</td>
        <td>{props.site_pros_vu}</td>
        <td>
          <button className="ML10 ActionButton BPurple" onClick={() => redirectToDetails()}><i className="fa-solid fa-info"></i></button>
          {props.overview ? '' : <button onClick={() => confirmWarmList()} className="ML10 ActionButton BRed"><i className="fa-solid fa-xmark"></i></button>}
        </td>
    </tr>
  );
}

export default WarmListRowComponent;
