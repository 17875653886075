import './EasyExportComponent.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { DayPicker } from 'react-day-picker';
import { format } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { ROOT_DOMAIN_API } from '../../Constant';

function EasyExportComponent(props) {
    const [panelVisible, setPanelVisible] = useState(false);
    const [vuAmount, setVUAmount] = useState("-1");
    const [vuAmountLabel, setVUAmountLabel] = useState("Everything");
    const [salesList, setSalesList] = useState([]);
    const [exportType, setExportType] = useState("prospected");
    const [filterType, setFilterType] = useState("everything");
    const [salesSelected, setSalesSelected] = useState("0");
    const [uidFile, setUIDFile] = useState("-1");

    const now = new Date();

    const [selectedDay1, setSelectedDay1] = useState(format(new Date(now.getFullYear(), now.getMonth(), 1), 'yyyy-MM-dd'));
    const [sD1Formatted, setSD1Formatted] = useState(format(new Date(now.getFullYear(), now.getMonth(), 1), 'yyyy-MM-dd'));
    const [dayPickerVisibility1, setDayPickerVisibility1] = useState(false);

    const [selectedDay2, setSelectedDay2] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [sD2Formatted, setSD2Formatted] = useState(format(new Date(), 'yyyy-MM-dd'));
    const [dayPickerVisibility2, setDayPickerVisibility2] = useState(false);

    const launchExport = function(){
        setUIDFile("-1");
        let vuAmountToSend = "infinity-infinity";
        switch(vuAmount){
            case "-1":
                vuAmountToSend = "infinity-infinity";
                break;
            case "0":
                vuAmountToSend = "0-10000";
                break;
            case "1":
                vuAmountToSend = "10000-100000";
                break;
            case "2":
                vuAmountToSend = "100000-infinity";
                break;
            default:
                vuAmountToSend = "infinity-infinity";
                break;
        }
        (async () => {
            try {
              const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_createEasyExport", {export_type: exportType, export_filter: filterType, date_from: sD1Formatted, date_to: sD2Formatted, prospector: salesSelected, vu: vuAmountToSend});
              const obj = await resp.data;
              if(obj.results){
                setUIDFile(obj.results)
              }
            } catch (error) {
                console.error(error);
            }
          })();
    }

    const toggleVisibility = function(){
        if(panelVisible){
            setPanelVisible(false);
        } else {
            setPanelVisible(true);
        }
    }

    const toggleDayPickerVisibility1 = function(){
        if(dayPickerVisibility1){
          setDayPickerVisibility1(false);
        } else {
          setDayPickerVisibility1(true);
        }
    }

    const toggleDayPickerVisibility2 = function(){
        if(dayPickerVisibility2){
          setDayPickerVisibility2(false);
        } else {
          setDayPickerVisibility2(true);
        }
    }

    const handleChangeVuAmount = function(e){
        setVUAmount(e.target.value);
    }

    const downloadExport = function(){
        if(uidFile !== '-1'){
            (async () => {
                try {
                    const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_downloadEasyExport", {uidFile: uidFile});
                    const obj = await resp.data;
                    if(obj.results){
                        function downloadCSV(csvContent, fileName) {

                            const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

                            const link = document.createElement('a');

                            const url = URL.createObjectURL(blob);
                            link.setAttribute('href', url);

                            link.setAttribute('download', fileName);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(url);
                        }

                        downloadCSV(JSON.parse(obj.results).body, 'export_Pros.csv');
                    }
                } catch (error) {
                    console.error(error);
                }
            })();
        }
    }

    const handleTypeExport = function(e){
        if(e.target.checked){
            setExportType("subscribed");
        } else {
            setExportType("prospected");
        }
    }

    const handleFilterExport = function(e){
        if(e.target.checked){
            setFilterType("activated");
        } else {
            setFilterType("everything");
        }
    }

    useEffect(() => {
        (async () => {
            try {
              const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_salesList");
              const obj = await resp.data;
              if(obj.results){
                setSalesList(obj.results);
              }
            } catch (error) {
                console.error(error);
            }
          })();
    }, []);

    useEffect(() => {
        switch(vuAmount){
            case "-1":
                setVUAmountLabel("Everything");
                break;
            case "0":
                setVUAmountLabel("Between 0 and 10 000");
                break;
            case "1":
                setVUAmountLabel("Between 10 000 and 100 000");
                break;
            case "2":
                setVUAmountLabel("More than 100 000");
                break;

            default:
                setVUAmountLabel("More than 100 000");
            break;
        }
    }, [vuAmount]);

    const handleSalesSelect = function(e){
        setSalesSelected(e.target.value)
    }

    useEffect(() => {
        setSD1Formatted(format(new Date(selectedDay1), 'yyyy-MM-dd'));
        setSD2Formatted(format(new Date(selectedDay2), 'yyyy-MM-dd'));
        setDayPickerVisibility1(false);
        setDayPickerVisibility2(false);
    }, [selectedDay1, selectedDay2])


  return (
    <div className={"EasyExport " + props.childClass}>
        <div className="FlexRow AlignCenter">
            <button onClick={() => toggleVisibility()} className="ActionButton BRed FullWidth FlexRow AlignCenter JustifyBetween">
                <i className="MediumIcon fa-solid fa-fire"></i>
                <h2 className="MT0 MB0 ML10">Quick export tool</h2>
                {panelVisible ? <i className="MediumIcon fa-solid fa-arrow-down"></i> : <i className="MediumIcon fa-solid fa-arrow-right"></i>}
            </button>
        </div>
        <div className={"EasyExportContainer MT10 " + (panelVisible ? "" : "Hide")}>
            <div className="FlexRow FullWidth JustifyAround">
                <div className="FlexRow">
                    <div className="FlexCol DateCol JustifyBetween">
                        <div className="FlexCol">
                            <button className="ActionButton BRed" onClick={() => toggleDayPickerVisibility1()}>
                                <i className="fa-solid fa-calendar"></i>&nbsp;From {sD1Formatted}</button>
                            <DayPicker
                                    format="M/D/YYYY"
                                    className={"DayPickerObject " + (dayPickerVisibility1 ? '' : 'Hide')}
                                    mode="single"
                                    selected={selectedDay1}
                                    onSelect={setSelectedDay1}
                                />
                        </div>
                        <div className="FlexCol MT5">
                            <button className="ActionButton BRed" onClick={() => toggleDayPickerVisibility2()}>
                                <i className="fa-solid fa-calendar"></i>&nbsp;To {sD2Formatted}</button>
                            <DayPicker
                                    format="M/D/YYYY"
                                    className={"DayPickerObject " + (dayPickerVisibility2 ? '' : 'Hide')}
                                    mode="single"
                                    selected={selectedDay2}
                                    onSelect={setSelectedDay2}
                                />
                        </div>
                        <div className="FlexRow JustifyBetween AlignCenter MT10">
                            <span className={exportType === "prospected" ? "" : "Unselected"}>Prospected</span>
                            <label className="switch">
                                <input type="checkbox" onClick={handleTypeExport}/>
                                <span className="slider round"></span>
                            </label>
                            <span className={exportType === "subscribed" ? "" : "Unselected"}>Subscribed</span>
                        </div>

                        <div className="FlexRow JustifyBetween AlignCenter MT10">
                            <span className={filterType === "everything" ? "" : "Unselected"}>Everything</span>
                            <label className="switch">
                                <input type="checkbox" onClick={handleFilterExport}/>
                                <span className="slider round"></span>
                            </label>
                            <span className={filterType === "activated" ? "" : "Unselected"}>Activated</span>
                        </div>
                        
                    </div>
                    <div className="FlexCol ML10 OptionsCol">
                        <div className="FlexCol VuSimilarRange">
                            <label>Vu Similar : {vuAmountLabel}</label>
                            <input className="RangeInput" onChange={handleChangeVuAmount} type="range" id="vuAmount" name="vuAmount" value={vuAmount} min="-1" max="2"/>
                        </div>
                        <div className="FlexCol ML5">
                            <label>Prospectors :</label>
                            <select onChange={handleSalesSelect} value={salesSelected}>
                                <option value="0">Everyone</option>
                                    {salesList.map((row, key) => {
                                    return <option key={key} value={row.pros_id}>{row.user_pros_name}</option>
                                    })}
                            </select> 
                        </div>
                    </div>
                </div>
                <div className="FlexCol AlignCenter JustifyCenter MiddleArrow">
                    <i className="TGrey fa-sharp fa-solid fa-arrow-right"></i>
                </div>
                <div className="FlexRow AlignCenter">
                    <button className="ActionButton BRed DownloadButton" onClick={launchExport}>
                        <i className="fa-sharp fa-solid fa-gear MR5"></i>
                        Request export
                    </button>
                </div>
                <div className="FlexCol AlignCenter JustifyCenter MiddleArrow">
                    <i className="TGrey fa-sharp fa-solid fa-arrow-right"></i>
                </div>
                <div className="FlexRow AlignCenter">
                    <button onClick={downloadExport} className={"ActionButton BRed DownloadButton "+(uidFile === "-1" ? "NoDwnload" : "")}>
                        {uidFile === "-1" ? <i className="fa-solid fa-ban MR5"></i> : <i className="fa-solid fa-check MR5"></i>}
                        {uidFile === "-1" ? 'No export has been requested' : 'Download export'}
                    </button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default EasyExportComponent;
