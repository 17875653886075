import './PodiumComponent.css';
import axios from "axios";
import { useEffect, useState } from "react";
import { ROOT_AVATAR_API, ROOT_DOMAIN_API } from '../../Constant';

function PodiumComponent(props) {
    const [podiumList, setPodiumList] = useState([]);

    useEffect(()=>{
        (async () => {
            try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_podium");
            const obj = await resp.data;
            if(obj.results!=null){
                setPodiumList(obj.results)
            }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [])


  return (
    <div className={"Podium FlexCol " + props.childClass}>
        <h2 className="M0 TCenter">Podium last month</h2>
        <div className="FlexRow JustifyCenter FlexBottom">
            <div className="FlexCol AlignCenter">
                <h4 className="MB0">{podiumList[1] ? podiumList[1].user_pros_name : ''}</h4>
                <p className="MT0">{podiumList[1] ? podiumList[1].total : '/'}</p>
                {podiumList[1] ? <img className="PodiumAvatar" alt="podium_2" src={ROOT_AVATAR_API+(podiumList[1].user_pros_avatar_seed ?? "")+"&mouth=smile&clothing=graphicShirt&clothingGraphic=bear"} /> : ''}
                <div className="Podium2"></div>
            </div>
            <div className="FlexCol AlignCenter">
                <h4 className="MB0">{podiumList[0] ? podiumList[0].user_pros_name : ''}</h4>
                <p className="MT0">{podiumList[0] ? podiumList[0].total : '/'}</p>
                {podiumList[0] ? <img className="PodiumAvatar" alt="podium_1" src={ROOT_AVATAR_API+(podiumList[0].user_pros_avatar_seed ?? "")+"&mouth=smile&clothing=graphicShirt&clothingGraphic=bear"} /> : ''}
                <div className="Podium1"></div>
            </div>
            <div className="FlexCol AlignCenter">
                <h4 className="MB0">{podiumList[2] ? podiumList[2].user_pros_name : ''}</h4>
                <p className="MT0">{podiumList[2] ? podiumList[2].total : '/'}</p>
                {podiumList[2] ? <img className="PodiumAvatar" alt="podium_3" src={ROOT_AVATAR_API+(podiumList[2].user_pros_avatar_seed ?? "")+"&mouth=smile&clothing=graphicShirt&clothingGraphic=bear"} /> : ''}
                <div className="Podium3"></div>
            </div>
        </div>
    </div>
  );
}

export default PodiumComponent;
