import './RealTimeCardRowComponent.css';
import { useNavigate } from 'react-router-dom';

function RealTimeCardRowComponent(props) {
  const navigate = useNavigate();

    const redirectToDetails = function(){
        let encodedUrl = btoa(props.site_pros_url);
        navigate('/pros_details/'+encodedUrl, { replace: false });
    }
  return (
    <div className="DetailsRow FlexRow JustifyBetween AlignCenter">
        <span className="FlexRow AlignCenter">
            <img className="MR10" alt="avatar" src={props.user_pros_avatar.replace("null", "-1")}/>
            <div className="FlexCol MT5 MB5">
              <h4 className="M0">{props.site_pros_url}</h4>
              <h6 className="M0">{props.user_pros_name}</h6>
            </div>
        </span>
        <span>
            {props.site_pros_datetime}
            <button onClick={() => redirectToDetails()} className="ML20 ActionButton BPurple"><i className="TWhite fa-solid fa-info"></i></button>
        </span>
    </div>
  );
}

export default RealTimeCardRowComponent;
