import './ProgressBarComponent.css'

function ProgressBarComponent(props) {
  return (
    <div className="ProgressBar BLightGrey">
        <div className={"ContentProgressBar " + props.contentColor} style={{width: props.percentage+"%"}}></div>
    </div>
  );
}
  
export default ProgressBarComponent;