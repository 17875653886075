import { useNavigate} from "react-router-dom";
import { useEffect } from "react";
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant"
axios.defaults.withCredentials = true;

function AuthAdminComponent() {
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_admin/prospection_ping");
        const obj = await resp.data;
        if(obj.error!=="GENERIC_SUCCESS"){
          navigate('/pros', { replace: true });
        }
      } catch (error) {
          console.error(error);
          navigate('/login/', { replace: true });
      }
  })();
  });

  return (
    <div className="AdminAuth"></div>
  );
}

export default AuthAdminComponent;
