import './SalesUserComponent.css'
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthComponent from '../../components/AuthComponent/AuthComponent';
import SplashLoadComponent from '../../components/SplashLoadComponent/SplashLoadComponent';
import LastSubscriptionSalesComponent from "../../components/LastSubscriptionSalesComponent/LastSubscriptionSalesComponent";
import LastProspectionSalesComponent from "../../components/LastProspectionSalesComponent/LastProspectionSalesComponent";
import HeadLineComponent from '../../components/HeadLineComponent/HeadLineComponent';
import LateralNavComponent from '../../components/LateralNavComponent/LateralNavComponent';
import SalesDetailsWarmlistComponent from '../../components/SalesDetailsWarmlistComponent/SalesDetailsWarmlistComponent';
import GraphSalesProspectedComponent from '../../components/GraphSalesProspectedComponent/GraphSalesProspectedComponent';
import GraphSalesSubscribedComponent from '../../components/GraphSalesSubscribedComponent/GraphSalesSubscribedComponent';
import LastConfirmedWebsitesComponent from '../../components/LastConfirmedWebsitesComponent/LastConfirmedWebsitesComponent';
import PayPlanComponent from '../../components/PayPlanComponent/PayPlanComponent';
import { ROOT_DOMAIN_API } from '../../Constant';

function SalesUserComponent() {
  let { id } = useParams();
  const [splashLoad, setSplashLoad] = useState(true);
  const [salesItem, setSalesItem] = useState({user_pros_id: null, user_pros_name: null, user_pros_avatar: null})

  useEffect(() => {
    if(!id||isNaN(id)){
      return;
    }

    (async () => {
      try {
          const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospectionSalesDetailsById?q="+id);
          const obj = await resp.data;
          if(obj.results!=null){
            setSalesItem(obj.results)
          }
      } catch (error) {
          console.error(error);
      }
      
    })()

    setSplashLoad(true);
    setTimeout(() => {
      setSplashLoad(false);
    }, 3000)
  }, [id]);

  return (
    <div className="SalesUser">
      <AuthComponent/>
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent />
      <HeadLineComponent title={"Sales details page"} intro={salesItem.user_pros_name} avatar={salesItem.user_pros_avatar} childClass="Headline"/>
      <PayPlanComponent pros_id={id} childClass="PayPlanCard" />
      <SalesDetailsWarmlistComponent childClass="SalesWarmlistCard" pros_name={salesItem.user_pros_name} pros_id={id}/>
      <LastProspectionSalesComponent pros_id={id} childClass="SalesLastProsCard"/>
      <LastSubscriptionSalesComponent pros_id={id} childClass="SalesLastSubCard"/>
      <GraphSalesProspectedComponent pros_id={id} childClass="SalesProspectedGraphCard" title={"Prospected website"}/>
      <GraphSalesSubscribedComponent pros_id={id} childClass="SalesSubscribedGraphCard" title={"Subscribed website"}/>
      <LastConfirmedWebsitesComponent pros_id={id} childClass="SalesLastConfirmedWebsitesCard" title={"Last Confirmed Websites"}/>
    </div>
  );
}
  
export default SalesUserComponent;
  