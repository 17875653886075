import './HeaderComponent.css';

function HeaderComponent() {
  return (
    <div>
      <nav className="header">
          <img src="/logo_backoffice.png" className="App-logo" alt="logo" />
      </nav>
      <div className="HeaderSpacer"></div>
    </div>
  );
}

export default HeaderComponent;
