import './BonusOverviewComponent.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate} from "react-router-dom";
import { ROOT_DOMAIN_API } from '../../Constant';

function BonusOverviewComponent(props) {
  const [date, setDate] = useState(false);
  const [dateList, setDateList] = useState([]);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [cacheDate, setCacheDate] = useState(false)

  const navigate = useNavigate();

    function loadData(){
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_admin/getSalesPayPlanActivationHistoryOverview");
                const obj = await resp.data;
                if(obj.status===true){
                    setData(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        setDate(dateList[0]);
    }, [dateList])

    useEffect(() => {
        setSelectedData([]);
        setCacheDate(false);
        for (const [k, r] of Object.entries(data)) {
            if(k===date){
                let d = Object.keys(r).map((key) => r[key]);
                setSelectedData(d);
                setCacheDate(d[0]["cache_date"]);
            }
        }
    }, [data, date]);

    useEffect(() => {
        let today = new Date();
        let tempDateList = [];
        
        for (let i = 0; i <= 5; i++) {
            let year = today.getFullYear();
            let month = today.getMonth() - i;
            let date = new Date(year, month, 1, 12);
            tempDateList.push(date.toISOString().split('T')[0]);
        }
        
        setDateList(tempDateList);
    }, []);

    const loadSalesDetails = function(salesId){
        navigate('/sales/'+salesId, { replace: false });
    }

  return (
    <div className={"BonusOverview " + props.childClass}>
        <div className='FlexRow JustifyBetween'>
            <div className='FlexRow'>
                <h2 className="M0">Activation bonus overview</h2>
                <h6 className='FlexRow AlignCenter M0 ML15'>{cacheDate ? "Cache from: " + (new Date(cacheDate * 1000).toISOString().split("T")[0] + " " + new Date(cacheDate * 1000).toTimeString().split(" ")[0]) : ''}</h6>
            </div>
            <select className="BonusOverviewSelector" value={date} onChange={(e) => {setDate(e.target.value)}}>
                {dateList.map((row, key) => {
                            return (<option key={key} value={row}>
                                    {row}
                                </option>)
                        })}
            </select>
        </div>
        <div className="BonusOverviewContainer">
            <table className="Width100">
                <thead className='TWhite BRed'>
                    <tr>
                        <th>Sales</th>
                        <th>Bonus €</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedData.map((row, key) => {
                        return (<tr key={key}>
                            <td>
                                <button className="ActionButton BPurple" onClick={() => loadSalesDetails(row.user_pros_id)}><i className="fa-solid fa-magnifying-glass"></i></button>
                                <span className='ML10'>{row.user_pros_name}</span>
                            </td>
                            <td>{row.bonus}€</td>
                            <td>{row.date}</td>
                        </tr>)
                    })}
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                </tbody>
            </table>
        </div>
    </div>
  );
}
  
export default BonusOverviewComponent;
  