import './App.css';
import HeaderComponent from './components/HeaderComponent/HeaderComponent';
import LoginComponent from './pages/LoginComponent/LoginComponent';
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import Home from './components/Home';
import axios from 'axios';
import AdminComponent from './pages/AdminComponent/AdminComponent';
import LogoutComponent from './pages/LogoutComponent/LogoutComponent';
import SalesUserComponent from './pages/SalesUserComponent/SalesUserComponent';
import ProsDetailsComponent from './pages/ProsDetailsComponent/ProsDetailsComponent';

axios.defaults.withCredentials = true;

function App() {

  return (
    <div className="App">
      <HeaderComponent/>
        <BrowserRouter className="lateralNavbar" basename={"/"}>
          <Routes>
              <Route path="/" element={<Home />}/>
              <Route path="/logout" element={<LogoutComponent/>} />
              <Route path="/login" element={<LoginComponent/>} />
              <Route path="/admin" element={<AdminComponent/>} />
              <Route path="/sales/:id" element={<SalesUserComponent/>} />
              <Route path="/pros_details/:id" element={<ProsDetailsComponent/>} />
              <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.2/css/all.min.css"/>
    </div>
  );
}

export default App;
