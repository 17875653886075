import './WebsiteConsolidationComponent.css';
import { useState, useEffect } from 'react';
import WebsiteConsolidationRowComponent from '../WebsiteConsolidationRowComponent/WebsiteConsolidationRowComponent';
import axios from 'axios';
import { ROOT_DOMAIN_API } from '../../Constant';

function WebsiteConsolidationComponent(props) {

    const [listWebsite, setListWebsite] = useState([]);
    const [panelVisible, setPanelVisible] = useState(false);
    const [refreshData, setRefreshData] = useState(0);
    const [optionsTags, setOptionsTags] = useState([]);
    const [csmList, setCsmList] = useState([]);

    const toggleVisibility = function(){
        if(panelVisible){
            setPanelVisible(false);
        } else {
            setPanelVisible(true);
        }
    }

    const refresh = function(){
        setRefreshData(Date.now())
    }

    useEffect(() => {
        (async () => {
          try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/csmList");
            const obj = await resp.data;
            if(obj.status&&obj.results){
              setCsmList(obj.results);
            }
          } catch (error) {
              console.error(error);
          }
      })()
      }, [])
    
    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_pendingDataConsolidation");
                const obj = await resp.data;
                if(obj.results!=null){
                    setListWebsite(obj.results)
                }
            } catch (error) {
                console.error(error);
            }
            
        })()
    }, [refreshData])

    useEffect(() => {
        (async () => {
          try {
              const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_dataTags");
              const obj = await resp.data;
              if(obj.status&&obj.results){
                let optionsList = []
                obj.results.map(row => {
                    if(row['ptl_id']==="22"||row['ptl_id']==="23"){
                        return true;
                    }
                    optionsList.push({label: row['ptl_label'], value: row['ptl_id']});
                    return true;
                })
                setOptionsTags(optionsList);
              }
          } catch (error) {
              console.error(error);
          }
          
      })()
      }, [])
  return (
    <div className={"WebsiteConsolidation " + props.childClass}>
        <div className="FlexRow AlignCenter">
            <button onClick={() => toggleVisibility()} className="ActionButton BRed FullWidth FlexRow AlignCenter JustifyBetween">
                <i className="MediumIcon fa-solid fa-rotate"></i>
                <h2 className="MT0 MB0 ML10">Website consolidation</h2>
                {panelVisible ? <i className="MediumIcon fa-solid fa-arrow-down"></i> : <i className="MediumIcon fa-solid fa-arrow-right"></i>}
            </button>
        </div>
        <div className={"WebsiteConsolidationList MT10 " + (panelVisible ? "" : "Hide")}>
            <table>
                <thead className="BRed TWhite">
                    <tr>
                        <th>Domain</th>
                        <th>Feedbacks</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {listWebsite.map((row, key) => {
                        return <WebsiteConsolidationRowComponent
                            key={key}
                            domain={row.site_pros_domain}
                            date={row.site_date}
                            url={row.site_pros_url}
                            csmList={csmList}
                            attribution={row.user_admin_id}
                            mailContact={row.site_pros_contact_mail}
                            siteProsId={row.site_pros_id}
                            siteId={row.site_pros_site_id}
                            type={row.site_pros_com}
                            refresh={refresh}
                            optionsTags={optionsTags}
                            />
                    })}
                </tbody>
            </table>
        </div>
    </div>
  );
}

export default WebsiteConsolidationComponent;