import { useNavigate} from "react-router-dom";
import { useEffect} from 'react';
import axios from 'axios';
import {ROOT_DOMAIN_API} from "../../Constant"
axios.defaults.withCredentials = true;

function AuthComponent() {
  const navigate = useNavigate();

  useEffect(()=>{
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_ping");
        const obj = await resp.data;
        if(obj.error!=="GENERIC_SUCCESS"){
          navigate('/login/', { replace: true });
        }

        let data = obj.results;
        localStorage.setItem('pros_adm_name', data.username);
        localStorage.setItem('pros_adm_avatar', data.user_pros_avatar);
      } catch (error) {
          console.error(error);
          navigate('/login/', { replace: true });
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className="Auth"></div>
  );
}

export default AuthComponent;
