import "./LastConfirmedWebsitesRowComponent.css";
import 'react-day-picker/dist/style.css';
import LabelCardComponent from "../LabelCardComponent/LabelCardComponent";

function LastConfirmedWebsitesRowComponent(props) {
    return (
        <div className={"FlexCol MT5 BoxRow " + props.childClass} >
            <p className="BRed TWhite P5">{props.url} | {props.site_id}</p>
            <div className="FlexRow WrapContent AlignAround">
                <LabelCardComponent childClass="MR10 MT5 Alternative" icon="fa-solid fa-question" color="BGreen" text={props.pros_com}/>
                {props.mail ? <LabelCardComponent childClass="MR10 MT5 Alternative" icon="fa-solid fa-question" color="BRed" text={props.mail}/> : ""}
                
                {/*props.tags.map((row, key) => {
                    if(row.ptl_id==="22"||row.ptl_id==="23"){
                        return <LabelCardComponent childClass="MR5 MT5 Alternative" key={key} icon="fa-regular fa-comment" color="BPink" text={row.ptl_label}/>
                    } else {
                        return <LabelCardComponent childClass="MR5 MT5 Alternative" key={key} icon="fa-solid fa-tag" color="BPurple" text={row.ptl_label}/>
                    }
                })*/}
            </div>
            <p>{props.abstract}</p>
        </div>
    );
  }
    
  export default LastConfirmedWebsitesRowComponent;
    