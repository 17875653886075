import './LoginComponent.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ROOT_DOMAIN_API } from '../../Constant';
axios.defaults.withCredentials = true;

function LoginComponent() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [keepLogin, setKeepLogin] = useState(true);
    const navigate = useNavigate();

    const handleUsername = event => {
        setUsername(event.target.value);
    };
    
    const handlePassword = event => {
        setPassword(event.target.value);
    };

    const handleKeyPress = event => {
        if(event.key === 'Enter'){
          sendAuth();
        }
    }

    useEffect(() => {
        (async () => {
            try {
              const response = await axios.get(`${ROOT_DOMAIN_API}/manage/v1/pros_user/prospection_ping`);
              const { data } = response;

              console.log(data);
      
              if (data.error === "GENERIC_SUCCESS") {
                navigate('/', {replace: false});
              }
            } catch (error) {
              console.error(error);
            }
          })();
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendAuth = function(){
        (async () => {
            const resp = await axios.post(ROOT_DOMAIN_API+'/manage/v1/pros_user/prospection_login',
                {username: username, password: password, keepLogin: keepLogin}
            );
            const obj = await resp.data;
            if(obj.status===true){
                localStorage.setItem("avatar_seed", obj.results.avatar_seed);
                navigate('/', { replace: false });
                return false;
            } else {
                toast.error('Invalid credentials.', {
                    position: "bottom-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }
        })();
        return false;
    }

  return (
    <div className="loginComponent">
        <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
        <div className="FlexCol FormContainer">
            <h2>SIGN IN TO CONTINUE.</h2>
            <div className="InputContainer">
                <input type="text" placeholder="Username" onKeyPress={handleKeyPress} onChange={handleUsername} value={username}/>
            </div>
            <div className="InputContainer">
                <input type="password" placeholder="Password" onKeyPress={handleKeyPress} onChange={handlePassword} value={password}/>
            </div>
            <div className="CheckboxContainer FlexRow MB10">
                <input id='keepMeConnected' type="checkbox" checked={keepLogin} onClick={(e) => setKeepLogin(e.target.checked)} />
                <p className='M0'>
                    <label htmlFor='keepMeConnected'>Keep me connected</label>
                </p>
            </div>
            <button type="submit" onClick={sendAuth} className="ActionButton ML20">Login</button>
        </div>
    </div>
  );
}

export default LoginComponent;
