import './LineCardRowComponent.css';
import LabelCardComponent from "../LabelCardComponent/LabelCardComponent"
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useState } from 'react';
import { ROOT_DOMAIN_API } from '../../Constant';

function LineCardRowComponent(props) {
  const navigate = useNavigate();
  const [hidden, setHidden] = useState(false);

  const redirectToDetails = function(){
      let encodedUrl = btoa(props.site_url);
      navigate('/pros_details/'+encodedUrl);
  }

  const handlerPositiveFeedback = function(){
    (async () => {
      try {
        const resp = await axios.post(ROOT_DOMAIN_API+'/manage/v1/pros_user/prospection_addFeedback', {site_pros_id: props.site_pros_id, type: 1});
        const obj = await resp.data;
        if(obj.status){
          toast.success('🎉 This prospection has been marked with a positive feedback.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
            setHidden(true);
        }
      } catch (error) {
          console.error(error);
      }
    })();  
  }

  const handlerRemoveDunning = function(){
    (async () => {
      try {
        const resp = await axios.post('https://www.themoneytizer.com/pros_api/deleteSiteFromDunning', {rel_domain: props.pros_domain,});
        const obj = await resp.data;
        if(obj.status){
          toast.success('😥 This prospection has been removed from your dunning task.', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            });
          setHidden(true);
        }
      } catch (error) {
          console.error(error);
      }
    })();  
  }

  return (
    <div className={"LineCardRow FlexCol " + (hidden? "Hide " : "" ) + props.childClass}>
      <div className="FlexRow JustifyBetween">
        <div className="FlexCol JustifyBetween">
            { props.action==="first_contact" ? <a target="blank" href={"https://"+props.title} className="ALink URed TBlack"><h2 className="M0">{(props.siteId ? (props.siteId+' | '+props.title) : props.title)}</h2></a> : <h2 className="M0">{(props.siteId ? (props.siteId+' | '+props.title) : props.title)}</h2>}
            <div className="FlexRow">
              { props.action==="dunning" ? <LabelCardComponent childClass="MR10" icon={props.icon} color={props.iconColor} text={props.motif}/> : "" }
              { props.action==="data_consolidation" ? <LabelCardComponent childClass="MR10" icon={props.icon} color={props.iconColor} text={props.motif}/> : "" }
              { props.action==="last_sub" ? <LabelCardComponent childClass="MR10" icon={props.icon} color={props.iconColor} text={props.motif}/> : "" }
              { props.motifDate ? <LabelCardComponent childClass="MR10" icon={props.iconDate} color={props.iconColorDate} text={props.motifDate}/> : ""}
            </div>
            <a className="ActionLink MB5 MT5" target="blank" href={props.pros_url}>{props.pros_domain}</a>
        </div>
        <div className="FlexCol">
          <button className="ML10 ActionButton BPurple" onClick={() => redirectToDetails()}><i className="fa-solid fa-info"></i></button>
          { props.action === "first_contact" ? <button className="MT5 ML10 ActionButton BGreen" onClick={() => handlerPositiveFeedback()}><i className="fa-solid fa-thumbs-up"></i></button> : ""}
          { props.action === "dunning" ? <button className="MT5 ML10 ActionButton BRed" onClick={() => handlerRemoveDunning()}><i className="fa-solid fa-xmark"></i></button> : ""}
        </div>
      </div>
    </div>
  );
}

export default LineCardRowComponent;
