import './WarmListComponent.css';
import { useState, useEffect } from 'react';
import axios from 'axios';
import WarmListRowComponent from '../WarmListRowComponent/WarmListRowComponent';
import { ROOT_DOMAIN_API } from '../../Constant';

function WarmListComponent(props) {

    const [listWebsite, setListWebsite] = useState([]);
    const [panelVisible, setPanelVisible] = useState(false);

    const toggleVisibility = function(){
        if(panelVisible){
            setPanelVisible(false);
        } else {
            setPanelVisible(true);
        }
    }
    
    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_warmlist");
                const obj = await resp.data;
                if(obj.results!=null){
                    setListWebsite(obj.results)
                }
            } catch (error) {
                console.error(error);
            }
            
        })()
    }, [props.refresh])

  return (
    <div className={"WarmList " + props.childClass}>
        <div className="FlexRow AlignCenter">
            <button onClick={() => toggleVisibility()} className="ActionButton BRed FullWidth FlexRow AlignCenter JustifyBetween">
                <i className="MediumIcon fa-solid fa-fire"></i>
                <h2 className="MT0 MB0 ML10">My warmlist</h2>
                {panelVisible ? <i className="MediumIcon fa-solid fa-arrow-down"></i> : <i className="MediumIcon fa-solid fa-arrow-right"></i>}
            </button>
        </div>
        <div className={"WarmListContainer MT10 " + (panelVisible ? "" : "Hide")}>
            <table>
                <thead className="BRed TWhite">
                    <tr>
                        <th>Prospection date</th>
                        <th>Sales</th>
                        <th>Prospection type</th>
                        <th>Domain</th>
                        <th>VU</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {listWebsite.map((row, key) => {
                        return <WarmListRowComponent
                            key={key}
                            site_pros_id={row.site_pros_id}
                            site_pros_com={row.site_pros_com}
                            site_pros_date={row.site_pros_date}
                            site_pros_url={row.site_pros_url}
                            user_pros_name={row.user_pros_name}
                            site_pros_vu={row.site_pros_vu}
                            />
                    })}
                </tbody>
            </table>
        </div>
    </div>
  );
}

export default WarmListComponent;
