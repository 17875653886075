import { useEffect } from 'react';
import axios from 'axios';
import './ProsDetailsKarmaComponent.css';
import { useState } from 'react';

function ProsDetailsKarmaComponent(props) {
  const [feedbackList, setFeedbackList] = useState([]);
  useEffect(() => {
    if(!props.siteProsUrl){
      return;
    }
    (async () => {
      try {
          const resp = await axios.post("https://www.themoneytizer.com/pros_api/getKarmaUrl", {url: props.siteProsUrl});
          const obj = await resp.data;
          if(obj.results!==null){
            setFeedbackList(obj.results)
          }
      } catch (error) {
          console.error(error);
      }
    })();
  }, [props.siteProsUrl]);

  return (
    <div className="ProsDetailsKarma">
      {(feedbackList.length===0? <p>This domain has no feedback.</p> : "")}
      {feedbackList.map((row,key) => {
        return <div key={key} className={"MT10 " + (row.site_pros_feedback === 1 ? "FBPositive" : "FBNegative")}>{row.user_pros_name} indicated a {row.site_pros_feedback===1?"positive":"negative"} feedback for this url.</div>
      })}
    </div>
  );
}
    
export default ProsDetailsKarmaComponent;
    