var root_domain_api = "";

if(window.location.hostname === "localhost"){
    root_domain_api = "http://127.0.0.1";
}else{
    root_domain_api = "https://www.themoneytizer.com";
}

export const ROOT_DOMAIN_API = root_domain_api;
export const ROOT_AVATAR_API = "https://api.dicebear.com/8.x/avataaars/svg?seed=";
