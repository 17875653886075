import './LastWebsitesRowComponent.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import { ROOT_DOMAIN_API } from '../../Constant'

function LastWebsitesRowComponent(props) {
    const navigate = useNavigate();
    const [hidden, setHidden] = useState(false);

    const redirectToDetails = function(){
        let encodedUrl = btoa(props.site_pros_url);
        navigate('/pros_details/'+encodedUrl, { replace: false });
    }

    const addToWarmList = function(){
        (async () => {
            try {
              const resp = await axios.post("https://www.themoneytizer.com/pros_api/addToWarmList", {site_pros_id: props.site_pros_id});
              const obj = await resp.data;
              if(obj.results){
                props.refreshWarmList();
              }
            } catch (error) {
                console.error(error);
            }
          })();
    }

    const confirmWarmList = function(){
        confirmAlert({
            title: 'Confirmation required',
            message: 'Are you sure to do this ?',
            buttons: [
              {
                label: 'Yes',
                onClick: () => addToWarmList()
              },
              {
                label: 'No'
              }
            ]
          });
    }

    const handlerPositiveFeedback = function(){
      (async () => {
        try {
          const resp = await axios.post(ROOT_DOMAIN_API+'/manage/v1/pros_user/prospection_addFeedback', {site_pros_id: props.site_pros_id, type: 1});
          const obj = await resp.data;
          if(obj.status){
            toast.success('🎉 This prospection has been marked with a positive feedback.', {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              });
              setHidden(true);
          }
        } catch (error) {
            console.error(error);
        }
      })();  
    }

  return (
    <tr className={"LastWebsitesRow " + props.childClass + (hidden ? " Hide" : "")}>
        <td>{props.site_pros_date}</td>
        <td>{props.site_pros_com}</td>
        <td>{props.site_pros_url}</td>
        <td>{props.site_pros_vu}</td>
        <td className="TCenter">
            { !props.feedback ? <button className="MT5 ActionButton BGreen" onClick={() => handlerPositiveFeedback()}><i className="fa-solid fa-thumbs-up"></i></button> : ""}
            <button onClick={() => confirmWarmList()} className={"ActionButton BOrange "+(!props.feedback ? "ML10" : "")}><i className={"fa-solid fa-fire"}></i></button>
            <button className="ML10 ActionButton BPurple" onClick={() => redirectToDetails()}><i className="fa-solid fa-info"></i></button>
        </td>
    </tr>
  );
}

export default LastWebsitesRowComponent;
