import "./SalesDetailsWarmlistComponent.css"
import axios from 'axios';
import WarmListRowComponent from "../WarmListRowComponent/WarmListRowComponent";
import { useState, useEffect } from "react";
import { ROOT_DOMAIN_API } from "../../Constant";

function SalesDetailsWarmlistComponent(props) {
    const [listWebsite, setListWebsite] = useState([]);
    useEffect(() => {
        (async () => {
            try {
              const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_warmlist?pros_id="+props.pros_id);
              const obj = await resp.data;
              if(obj.results){
                setListWebsite(obj.results);
              }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.pros_id])


  return (
    <div className={"SalesDetailsWarmlist " + props.childClass}>
        <h2>Warmlist of {props.pros_name}</h2>
        <table>
            <thead className="BRed TWhite">
                <tr>
                    <th>Prospection date</th>
                    <th>Sales</th>
                    <th>Prospection type</th>
                    <th>Domain</th>
                    <th>VU</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                    {listWebsite.map((row, key) => {
                        return <WarmListRowComponent
                            key={key}
                            overview={true}
                            site_pros_id={row.site_pros_id}
                            site_pros_com={row.site_pros_com}
                            site_pros_date={row.site_pros_date}
                            site_pros_url={row.site_pros_url}
                            user_pros_name={row.user_pros_name}
                            site_pros_vu={row.site_pros_vu}
                            />
                    })}
                </tbody>
        </table>
    </div>
  );
}

export default SalesDetailsWarmlistComponent;
