
import LateralNavComponent from "./LateralNavComponent/LateralNavComponent";
import CardProgressComponent from "./CardProgressComponent/CardProgressComponent";
import './Home.css';
import axios from "axios";
import { useState, useEffect } from "react";
import HeadLineComponent from "./HeadLineComponent/HeadLineComponent";
import { ToastContainer } from "react-toastify";
import AuthComponent from "./AuthComponent/AuthComponent";
import SplashLoadComponent from "./SplashLoadComponent/SplashLoadComponent";
import PodiumComponent from "./PodiumComponent/PodiumComponent";
import SalesStatisticComponent from "./SalesStatisticComponent/SalesStatisticComponent";
import WebsiteConsolidationComponent from "./WebsiteConsolidationComponent/WebsiteConsolidationComponent";
import WarmListComponent from "./WarmListComponent/WarmListComponent";
import LemlistImportComponent from "./LemlistImportComponent/LemlistImportComponent";
import RealTimeProspection from "./RealTimeCardComponent/RealTimeProspection";
import RealTimeSubscription from "./RealTimeCardComponent/RealTimeSubscription";
import LastWebsitesComponent from "./LastWebsitesComponent/LastWebsitesComponent";
import GraphTotalSubscribedComponent from "./GraphTotalSubscribedComponent/GraphTotalSubscribedComponent";
import GraphTotalProspectedComponent from "./GraphTotalProspectedComponent/GraphTotalProspectedComponent";
import EasyExportComponent from "./EasyExportComponent/EasyExportComponent";
import LineCardComponent from "./LineCardComponent/LineCardComponent";
import {ROOT_DOMAIN_API} from "../Constant"

function Home() {
  const pageTab = "overview";
  
  const [splashLoad, setSplashLoad] = useState(true);
  
  const [tProspected, setTProspected] = useState(0);
  const [tProspectedLemlist, setTProspectedLemlist] = useState(0);
  const [tProspectedManual, setTProspectedManual] = useState(0);
  const [tSubscribed, setTSubscribed] = useState(0);
  const [warmListRefresh, setWarmListRefresh] = useState(0);

  const [prevTProspectedManual, setPrevTProspectedManual] = useState(0);
  const [prevTProspectedLemlist, setPrevTProspectedLemlist] = useState(0);
  const [prevTProspected, setPrevTProspected] = useState(0);
  const [prevTSubscribedP, setPrevTSubscribedP] = useState(0);

  const updateGlobalStats = function(target, value){
    if(target==="tProspected"){
      setTProspected(value);
    }

    if(target==="tProspectedLemlist"){
      setTProspectedLemlist(value);
    }

    if(target==="tProspectedManual"){
      setTProspectedManual(value);
    }

    if(target==="tSubscribed"){
      setTSubscribed(value);
    }
  }

  const refreshWarmList = function(){
    setWarmListRefresh(Date.now())
  }

  useEffect(() => {
    localStorage.setItem('crm_redirection', false);
    setSplashLoad(true);

    setTimeout(() => {
      setSplashLoad(false);
    }, 3000)
  }, []);

  useEffect(() => {
    (async () => {
        try {
            const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_statisticsList",
              {prev: true}
            );
            const obj = await resp.data;
            if(obj.results!=null){
                let total_subscribed_p = 0;
                obj.results.forEach((row) => {
                    total_subscribed_p += parseInt(row.subscription_total);
                });
                setPrevTSubscribedP(total_subscribed_p);

                let total_prospected = 0;
                obj.results.forEach((row) => {
                    total_prospected += parseInt(row.prospected_total);
                });
                setPrevTProspected(total_prospected);

                let total_prospected_manual = 0;
                obj.results.forEach((row) => {
                    total_prospected_manual += parseInt(row.prospected_manual);
                });
                setPrevTProspectedManual(total_prospected_manual);

                let total_prospected_lemlist = 0;
                obj.results.forEach((row) => {
                    total_prospected_lemlist += parseInt(row.prospected_lemlist);
                });
                setPrevTProspectedLemlist(total_prospected_lemlist);

            }
        } catch (error) {
            console.error(error);
        }
    })()
  }, []);

  return (
    <div className="Home">
      <AuthComponent/>
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        />
      <LateralNavComponent pageTab={pageTab}/>
      <HeadLineComponent childClass="Headline" title="Welcome to the prospection dashboard"/>
      <CardProgressComponent childClass="Card1" action="manual_prospection" number={tProspectedManual} subtitle="Manually prospected" icon="fa-regular fa-hand TPurple" progressColor="BPurple" prevValue={prevTProspectedManual}/>
      <CardProgressComponent childClass="Card2" action="lemlist_prospection" number={tProspectedLemlist} subtitle="Lemlist prospected" icon="fa-solid fa-robot TSky" progressColor="BSky" prevValue={prevTProspectedLemlist}/>
      <CardProgressComponent childClass="Card3" action="total_prospection" number={tProspected} subtitle="Totally prospected" icon="fa-solid fa-check TPink" progressColor="BPink" prevValue={prevTProspected}/>
      <CardProgressComponent childClass="Card4" action="total_subscription" number={tSubscribed} subtitle="Subscribed website" icon="fa-solid fa-check TYellow" progressColor="BYellow" prevValue={prevTSubscribedP}/>
      <PodiumComponent childClass="PodiumCard"/>
      <LineCardComponent childClass="LineCardFirstContact" headLineColor="BRed" data={[]} action="first_contact" title="Last prospections"/>
      <LineCardComponent childClass="LineCardReminder" headLineColor="BSoftPink" data={[]} action="dunning" title="Dunning to do"/>
      <LineCardComponent childClass="LineCardDataConsolidation" headLineColor="BPurple" data={[]} action="data_consolidation" title="Awaiting data consolidation"/>
      <LineCardComponent childClass="LineCardWarmlist" headLineColor="BOrange" data={[]} action="warmlist" title="WarmList"/>
      <LineCardComponent childClass="LineCardLastSubscription" headLineColor="BGreen" data={[]} action="last_sub" title="Confirmed websites"/>
      <GraphTotalProspectedComponent childClass="GraphProspectedCard"/>
      <GraphTotalSubscribedComponent childClass="GraphSubscribedCard"/>
      <RealTimeProspection childClass="RealTimeProspectionCard"/>
      <RealTimeSubscription childClass="RealTimeSubscriptionCard"/>
      <EasyExportComponent childClass="EasyExportCard"/>
      <WebsiteConsolidationComponent childClass="WebsiteConsolidationCard"/>
      <WarmListComponent refresh={warmListRefresh} childClass="WarmListCard"/>
      <LemlistImportComponent childClass="LemlistImportCard"/>
      <LastWebsitesComponent childClass="LastWebsitesCard" refreshWarmList={refreshWarmList}/>
      <SalesStatisticComponent updateGlobalStats={updateGlobalStats} childClass="SalesStatisticCard"/>
    </div>
  );
}

export default Home;
