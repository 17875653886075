import './WebsiteConsolidationRowComponent.css';
import { useState } from 'react';
import LabelCardComponent from '../LabelCardComponent/LabelCardComponent';
import { MultiSelect } from "react-multi-select-component";
import axios from 'axios';
import { toast } from 'react-toastify';
import { ROOT_DOMAIN_API } from '../../Constant';

function WebsiteConsolidationRowComponent(props) {
  const [mail, setMail] = useState("");
  const [abstract, setAbstract] = useState("");
  const [hide, setHide] = useState(false);
  const [negociationType, setNegociationType] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [csmAttribution, setCSMAttribution] = useState("-1");

  const mailHandler = function(evt){
    setMail(evt.target.value);
  }

  const abstractHandler = function(evt){
    setAbstract(evt.target.value);
  }

  const negociationTypeHandler = function(e){
    setNegociationType(e.target.value);
  }

  const syncData = function(siteId, selectedTags, mailContact, negotiation_value, csm){
    (async () => {
        try {
          let data = {site_id: siteId,
            text: abstract,
            selected_tags: selectedTags,
            negotiation_value: negotiation_value,
            csm_attribution: csm};
          const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_addDataSubscription", data);
          const obj = await resp.data;
          if(obj.status){
              toast.success('🐧 The data has been successfully saved for this site.', {
                  position: "bottom-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  });
              setHide(true);
          }
        } catch (error) {
            console.error(error);
        }
    })()
  }

  return (
    <tr className={"WebsiteConsolidationRow " + (props.childClass ? props.childClass : "")+" "+(hide ? "Hide" : "")}>
        <td className="P10">
          <div className="FlexCol">
            <div className="FlexRow">
              <LabelCardComponent icon={"fa-solid fa-tag"} color={"BPink Alternative"} text={props.siteId}/>
              <LabelCardComponent icon={"fa-solid fa-calendar-days"} childClass={"ML5 Alternative"} color={"BPurple"} text={props.date}/>
              <LabelCardComponent icon={"fa-solid fa-question"} childClass={"ML5 Alternative"} color={"BGreen"} text={props.type}/>
            </div>
            <a className="ActionLink" target="blank" href={props.url}>{props.domain}</a>
          </div>
        </td>
        <td className="P10">
          <div className="FlexRow">
            <div className="FlexCol Width50 AlignStart">
              <label htmlFor="tagsSelector">Email :</label>
              <input className="InputFeedback LightBorder" onChange={mailHandler} value={mail} type="mail"/>
              <label htmlFor="csm_attribution" className="MT20">CSM Attribution :</label>
              <select className="InputFeedback"
                id="csm_attribution"
                disabled={props.attribution!==null}
                value={csmAttribution}
                onChange={(e) => setCSMAttribution(e.target.value)}>
                <option value="-1">No attribution</option>
                {props.csmList.map((row, key) => {
                  return <option key={key} value={row.adm_id}>{row.adm_name}</option>
                })}
              </select>
              <label className="MT20" htmlFor="tagsSelector">Abstract :</label>
              <textarea className="TextFeedback" onChange={abstractHandler} value={abstract}></textarea>
            </div>
            <div className="FlexCol Width50 AlignStart ML10">
            <label htmlFor="tagsSelector">Negociation type :</label>
              <select className="InputFeedback" onChange={negociationTypeHandler}>
                <option value="negotiation">Negotiation</option>
                <option value="no_negotiation">No Negotiation</option>
              </select>
              <label className="MT20" htmlFor="tagsSelector">Tags :</label>
              {props.optionsTags.length > 0 ? <MultiSelect
                  className={"InputFeedback "}
                  options={props.optionsTags}
                  value={selectedTags}
                  onChange={setSelectedTags}
                  labelledBy="Select"
              /> : <h1>no data</h1>}
            </div>
          </div>
        </td>
        <td>
          <button onClick={() => {syncData(props.siteId, selectedTags, mail, negociationType, csmAttribution)}} className={"ActionButton BRed P15"}>
            Synchronize
          </button>
        </td>
    </tr>
  );
}

export default WebsiteConsolidationRowComponent;
