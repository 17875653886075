import "./ManageProspectionComponent.css";
import axios from "axios";
import { useState } from "react";
import ManageProspectionRowComponent from "./ManageProspectionRowComponent/ManageProspectionRowComponent";
import { ROOT_DOMAIN_API } from "../../Constant";

function ManageProspectionComponent(props) {
  const [searchValue, setSearchValue] = useState("");
  const [domainsToManage, setDomainsToManage] = useState([]);

  const handleSearch = function(){
    if(!searchValue||searchValue.length<3){
      return;
    }

    (async () => {
      try {
        const resp = await axios.get(ROOT_DOMAIN_API+"/manage/v1/pros_admin/prospection_searchManageProspection", {params: {domain: searchValue}});
        const obj = await resp.data;
        if(obj.results){
         setDomainsToManage(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
    })();
  }

  return (
    <div className={"ManageProspection " + props.childClass}>
        <h1>Manage prospection</h1>
        <div className="FlexCol">
            <div className="FlexRow JustifyAround">
                <div className="FlexCol Width100">
                  <p className="MT0">Search for a domain</p>
                  <div className="FlexRow">
                    <input className="SearchField" onChange={(e) => setSearchValue(e.target.value)} type="text"/>
                    <button onClick={() => {handleSearch();}} className="ActionButton BRed TWhite ML10">Search domain</button>
                  </div>
                </div>
            </div>
            <table className="MT20">
              <thead className="BRed TWhite">
                <tr>
                  <th>Date</th>
                  <th>Sales</th>
                  <th>Prospection type</th>
                  <th>URL</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {domainsToManage.map((row, key) => {
                  return <ManageProspectionRowComponent prospection={row}/>
                })}
              </tbody>
            </table>
        </div>
    </div>
  );
}

export default ManageProspectionComponent;
