import './LineCardComponent.css';
import LineCardRowComponent from '../LineCardRowComponent/LineCardRowComponent';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { ROOT_DOMAIN_API } from '../../Constant';

function LineCardComponent(props) {
  const [dunningList, setDunningList] = useState([]);
  const [firstContactList, setFirstContactList] = useState([]);
  const [dataConsolidationList, setDataConsolidationList] = useState([]);
  const [warmlistList, setWarmlistList] = useState([]);
  const [refresh, setRefresh] = useState([]);
  const [lastConfirmedList, setLastConfirmedList] = useState([]);

  useEffect(() => {
    switch(props.action){
      case "dunning":
        setDunningList([]);
        (async () => {
          try {
            const resp = await axios(ROOT_DOMAIN_API+'/manage/v1/pros_user/prospection_dunningToDo');
            const obj = await resp.data;
            if(obj.results){
              setDunningList(obj.results);
            }
          } catch (error) {
              console.error(error);
          }
        })();  
      break;
      case "first_contact":
        setFirstContactList([]);
        (async () => {
          try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_lastProspectedWebsites?limit=day");
            const obj = await resp.data;
            if(obj.results){
              setFirstContactList(obj.results);
            }
          } catch (error) {
              console.error(error);
          }
        })();  
      break;
      case "warmlist":
        setWarmlistList([]);
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_warmlist");
                const obj = await resp.data;
                if(obj.results!=null){
                  setWarmlistList(obj.results)
                }
            } catch (error) {
                console.error(error);
            }
            
        })()
      break;
      case "data_consolidation":
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_pendingDataConsolidation");
                const obj = await resp.data;
                if(obj.results!=null){
                    setDataConsolidationList(obj.results)
                }
            } catch (error) {
                console.error(error);
            }
            
        })()
        break;

      case "last_sub":
        (async () => {
          try {
            const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_lastConfirmedWebsites");
            const obj = await resp.data;
            if(obj.status===true){
              setLastConfirmedList(obj.results)
            }
          } catch (error) {
              console.error(error);
          }
        })();
        break;
        default:
          break;
    }
  }, [props.action, refresh]);

  const refreshDataHandler = function(){
    setRefresh(Date.now());
  }

  return (
    <div className={"LineCard FlexCol " + props.childClass}>
        <h2 className={"Headline "+ props.headLineColor}>{props.title}
          <button onClick={refreshDataHandler} className={"ML20 ActionButton " + props.headLineColor}><i className="fa-solid fa-arrows-rotate"></i></button>
        </h2>
        <div className="ContainerLineRowTable">
          {lastConfirmedList.map((row, index) => {
            return <LineCardRowComponent
              key={index}
              refresh={refreshDataHandler}
              childClass="LineCardRowElement"
              action={props.action}
              icon={"fa-solid fa-question"}
              iconColor={"BGreen"}
              motif={row.site_pros_com}
              pros_url = {row.site_pros_url}
              pros_domain = {row.site_pros_domain}
              motifDate = {(row.site_pros_date_inscrit)}
              iconColorDate={"BPurple"}
              iconDate={"fa-solid fa-calendar-days"}
            />
          })}
          {dunningList.map((row, index) => {
            return <LineCardRowComponent
              key={index}
              refresh={refreshDataHandler}
              childClass="LineCardRowElement"
              action={props.action}
              icon={"fa-solid fa-repeat"}
              iconColor={"BOrange"}
              motif={"Step "+ row.pros_step}
              pros_url = {row.site_pros_rel_url}
              pros_domain = {row.site_pros_rel_domain}
              motifDate = {(row.site_pros_rel_datetime.split(" ")[0])}
              iconColorDate={"BPurple"}
              iconDate={"fa-solid fa-calendar-days"}
            />
          })}
          {firstContactList.map((row, index) => {
            return <LineCardRowComponent
              key={index}
              refresh={refreshDataHandler}
              childClass="LineCardRowElement"
              action={props.action}
              icon={"fa-solid fa-repeat"}
              iconColor={"BOrange"}
              motif={"Step "+ row.pros_step}
              pros_url = {row.site_pros_url}
              pros_domain = {row.site_pros_domain}
              motifDate = {row.site_pros_date}
              site_pros_id={row.site_pros_id}
              iconColorDate={"BPurple"}
              iconDate={"fa-solid fa-calendar-days"}
            />
          })}
          {dataConsolidationList.map((row, index) => {
            return <LineCardRowComponent
            key={index}
            refresh={refreshDataHandler}
            childClass="LineCardRowElement"
            action={props.action}
            icon={"fa-solid fa-question"}
            iconColor={"BGreen"}
            motif={row.site_pros_com}
            pros_url = {row.site_pros_url}
            pros_domain = {row.site_pros_domain}
            motifDate = {row.site_date}
            site_pros_id={row.site_pros_id}
            iconColorDate={"BPurple"}
            iconDate={"fa-solid fa-calendar-days"}
          />
          })
          }
          {warmlistList.map((row, index) => {
            return <LineCardRowComponent
            key={index}
            refresh={refreshDataHandler}
            childClass="LineCardRowElement"
            action={props.action}
            icon={"fa-solid fa-fire"}
            iconColor={"BGreen"}
            motif={row.site_pros_com}
            pros_url = {row.site_pros_url}
            pros_domain = {row.site_pros_domain}
            motifDate = {row.site_date}
            site_pros_id={row.site_pros_id}
            iconColorDate={"BPurple"}
            iconDate={"fa-solid fa-calendar-days"}
          />
          })
          }
        </div>
    </div>
  );
}

export default LineCardComponent;
