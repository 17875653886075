import './SearchFormUserSiteComponent.css';

function Home() {

  return (
    <div className="SearchFromUserSite">
        
    </div>
  );
}

export default Home;
