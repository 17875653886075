import { useEffect, useState } from 'react';
import ProgressBarComponent from '../ProgressBarComponent/ProgressBarComponent';
import './CardProgressComponent.css';

function CardProgressComponent(props) {
  const [percentage, setPercentage] = useState();
  const [filteredTime, setFilteredTime] = useState();

  function SplitTime(numberOfHours){
    var Days=Math.floor(numberOfHours/24);
    var Remainder=numberOfHours % 24;
    var Hours=Math.floor(Remainder);
    return(Days+"d "+Hours+"h")
}

  useEffect(() => {
    if(props.action==="average_resolution_time"){
      let filteredValue = ((props.prevValue/props.number - 1))*100;
      if(filteredValue>100){
        filteredValue = 100;
      }
      if(filteredValue<0){
        filteredValue = 0;
      }
      setFilteredTime(SplitTime(props.number));
      setPercentage(parseFloat(filteredValue).toFixed(2));
    } else {
      let filteredValue = (props.number/props.prevValue)*100;
      if(filteredValue>100){
        filteredValue = 100;
      }
      setPercentage(parseFloat(filteredValue).toFixed(2));
    }
  }, [props.prevValue, props.number, props.action])

  return (
    <div className={"cardProgress " + props.childClass}>
      <div className="FlexRow FlexSpaceAround AlignCenter">
        <h2 className="M0">{filteredTime ? filteredTime : props.number}{props.currency}</h2>
        <i className={props.icon}></i>
      </div>
      <h5 className="Subtitle MT5">{props.subtitle}</h5>
      <div className="FlexRow AlignCenter JustifyBetween">
        <div className="ProgressBarContainer">
          {props.hideProgress === "true" ? '' : <ProgressBarComponent className="BLightGrey" contentColor={props.progressColor} percentage={percentage} />}
        </div>
        {props.hideProgress === "true" ? '' : <p className="TGrey">{percentage}%</p>}
      </div>
    </div>
  );
}

export default CardProgressComponent;
