import { useEffect, useState } from 'react';
import './LastSubscriptionSalesComponent.css'
import axios from 'axios';
import LastSubscriptionSalesRowComponent from "../LastSubscriptionSalesRowComponent/LastSubscriptionSalesRowComponent";
import { ROOT_DOMAIN_API } from '../../Constant';

function LastSubscriptionSalesComponent(props) {
  const [listWebsite, setListWebsite] = useState([]);
  useEffect(() => {
    (async () => {
      try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_prospectionLastSubscriptionBySale?pros_user_id="+props.pros_id);
        const obj = await resp.data;
        if(obj.results){
          setListWebsite(obj.results);
        }
      } catch (error) {
          console.error(error);
      }
  })();
  }, [props.pros_id])

  return (
    <div className={"LastSubscriptionSales " + props.childClass}>
      <h2>Last Subscriptions</h2>
      <div className="MaxHeightTable">
        <table>
          <thead className="BRed TWhite">
              <tr>
                  <th>Site ID</th>
                  <th>Type</th>
                  <th>Domain</th>
                  <th>Pros date</th>
                  <th>Sub date</th>
                  <th>Action</th>
              </tr>
          </thead>
          <tbody>
            {listWebsite.map((row, key) => {
              return <LastSubscriptionSalesRowComponent 
              site_id={row.site_pros_site_id}
              pros_type={row.site_pros_com}
              pros_date={row.site_pros_date}
              pros_sub={row.site_pros_datetime}
              site_url={row.site_pros_url}/>
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
  
export default LastSubscriptionSalesComponent;
  