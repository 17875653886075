import LateralNavComponent from "../../components/LateralNavComponent/LateralNavComponent";
import './AdminComponent.css';
import { useState, useEffect } from "react";
import { ToastContainer } from 'react-toastify';
import AuthAdminComponent from "../../components/AuthAdminComponent/AuthAdminComponent";
import SplashLoadComponent from "../../components/SplashLoadComponent/SplashLoadComponent";
import CaSalesComponent from "../../components/CaSalesComponent/CaSalesComponent";
import HeadLineComponent from "../../components/HeadLineComponent/HeadLineComponent";
import ImportAdminProspectionComponent from "../../components/ImportAdminProspectionComponent/ImportAdminProspectionComponent";
import ManageProspectionComponent from "../../components/ManageProspectionComponent/ManageProspectionComponent";
import BonusOverviewComponent from "../../components/BonusOverviewComponent/BonusOverviewComponent";


function AdminComponent() {
  const pageTab = "admin";
  const [splashLoad, setSplashLoad] = useState(true);
  
  setTimeout(() => {
    setSplashLoad(false);
  }, 5000)

  useEffect(() => {
    setSplashLoad(true);
  }, []);

  return (
    <div className="Admin">
      <AuthAdminComponent/>
      {splashLoad ? <SplashLoadComponent childClass="SplashLoadCard"/> : ''}
      <ToastContainer
          theme="colored"
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          />
          <LateralNavComponent pageTab={pageTab}/>
          <HeadLineComponent childClass={"HeadlineCard"} />
          <ImportAdminProspectionComponent childClass={"ImportCard"}/>
          <ManageProspectionComponent childClass={"ManageProspectionCard"}/>
          <BonusOverviewComponent childClass={"BonusOverviewCard"} />
          <CaSalesComponent childClass={"CaSalesCard"}/>
      </div>
  );
}

export default AdminComponent;
