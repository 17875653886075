import './PayPlanComponent.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { Line } from 'react-chartjs-2';
import { ROOT_DOMAIN_API } from '../../Constant';

function PayPlanComponent(props) {
  const [data, setData] = useState([]);
  const [monthBonus, setMonthBonus] = useState(0);
  const [graphData, setGraphData] = useState(null);
  const [fSelected, setFSelected] = useState("site_url");
  const [fOrder, setFOrder] = useState("ASC");

  useEffect(() => {
    (async () => {
        try {
        const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/getSalesPayPlanActivationHistory?q="+props.pros_id);
        const obj = await resp.data;
        var dateList = [];
        var amountList = [];

        if(obj.status===true){
            obj.results.forEach((row, key) => {
            dateList.push(row.date);
            amountList.push(row.bonus);
            })

            let lastEl =  obj.results[obj.results.length - 1];
            setMonthBonus(lastEl.bonus);

            const labels = dateList;
            var data = {
                labels,
                datasets: [
                    {
                        label: 'Bonus History',
                        lineTension: 0.8,
                        data: amountList,
                        fill: true,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.4)',
                        yAxisID: 'y',
                    },
                ],
            };

            setGraphData(data);
        }
        } catch (error) {
            console.error(error);
        }
    })();
  }, [props.pros_id]);

    useEffect(() => {
        (async () => {
            try {
                const resp = await axios(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_SalesPayPlanActivation",
                    {params: {q: props.pros_id}});
                const obj = await resp.data;
                if(obj.status===true){
                    setData(obj.results);
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [props.pros_id, fSelected, fOrder]);

    const toggleFilter = function(target){
        if(target===fSelected){
            if(fOrder==="ASC"){
                setFOrder("DESC");
            } else {
                setFOrder("ASC");
            }
            return;
        }

        setFSelected(target);
        setFOrder("ASC");
    }

  return (
    <div className={"PayPlan "+ (data.length > 0 ? "" : "Hide") + " " + props.childClass}>
        <h2 className="M0">Activation bonus</h2>
        <div className='FlexCol'>
            <div className='FlexRow AlignCenter JustifyCenter BonusMonth'>
                <i className="fa-solid fa-piggy-bank TRed"></i>
                <div>{monthBonus}€</div>
            </div>
            <h3 className="TitleBonusMonth">Bonus this month</h3>
        </div>
        <div className="PayPlanContainer">
            <table>
                <thead className='TWhite BRed'>
                    <tr>
                        <th>Site URL 
                            <span className='PB15 FlexCol AlignCenter JustifyCenter'>
                                <i onClick={() => {toggleFilter("site_url")}} className={"filter-button fa-solid "+(fOrder==="DESC"&&fSelected==="site_url" ? "fa-sort-down " : "fa-sort-up ")+(fSelected==="site_url" ? "filter-active " : "")}></i>
                            </span>
                        </th>
                        <th>Since 
                            <span className='PB15 FlexCol AlignCenter JustifyCenter'>
                                <i onClick={() => {toggleFilter("since_date")}} className={"filter-button fa-solid "+(fOrder==="DESC"&&fSelected==="since_date" ? "fa-sort-down " : "fa-sort-up ")+(fSelected==="since_date" ? "filter-active " : "")}></i>
                            </span>
                        </th>
                        <th>Until
                            <span className='PB15 FlexCol AlignCenter JustifyCenter'>
                                <i onClick={() => {toggleFilter("until_date")}} className={"filter-button fa-solid "+(fOrder==="DESC"&&fSelected==="until_date" ? "fa-sort-down " : "fa-sort-up ")+(fSelected==="until_date" ? "filter-active " : "")}></i>
                            </span>
                        </th>
                        <th>Bonus earned
                            <span className='PB15 FlexCol AlignCenter JustifyCenter'>
                                <i onClick={() => {toggleFilter("total_bonus")}} className={"filter-button fa-solid "+(fOrder==="DESC"&&fSelected==="total_bonus" ? "fa-sort-down " : "fa-sort-up ")+(fSelected==="total_bonus" ? "filter-active " : "")}></i>
                            </span>
                        </th>
                        <th>This month
                            <span className='PB15 FlexCol AlignCenter JustifyCenter'>
                                <i onClick={() => {toggleFilter("month_bonus")}} className={"filter-button fa-solid "+(fOrder==="DESC"&&fSelected==="month_bonus" ? "fa-sort-down " : "fa-sort-up ")+(fSelected==="month_bonus" ? "filter-active " : "")}></i>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, key) => {
                        return (<tr>
                            <td>{row.site_url}</td>
                            <td>{row.since_date}</td>
                            <td>{row.until_date}</td>
                            <td>{row.total_bonus ?? '0'}€</td>
                            <td>{row.month_bonus ?? '0'}€</td>
                        </tr>)
                    })}
                    <tr></tr>
                    <tr></tr>
                    <tr></tr>
                </tbody>
            </table>
        </div>
        <h2 className="MT20">Bonus history</h2>
        <div className={"PayPlanGraphHistory MT10" + props.childClass}>
            {graphData !== null ? <Line height="250px" className="PayPlanGraphCanvas" data={graphData} options={{ maintainAspectRatio: false }} /> : ''}
        </div>
    </div>
  );
}
  
export default PayPlanComponent;