import { useNavigate } from "react-router-dom";
function LastSubscriptionSalesComponent(props) {
  const navigate = useNavigate();

  const redirectToDetails = function(){
      let encodedUrl = btoa(props.site_url);
      navigate('/pros_details/'+encodedUrl);
  }
  return (
    <tr className={"LastSubscriptionSalesRow " + props.childClass}>
        <td>{props.site_id}</td>
        <td>{props.pros_type}</td>
        <td>{props.site_url}</td>
        <td>{props.pros_date}</td>
        <td>{props.pros_sub}</td>
        <td>
          <button className="ML10 ActionButton BPurple" onClick={() => redirectToDetails()}><i className="fa-solid fa-info"></i></button>
        </td>
    </tr>
  );
}
  
export default LastSubscriptionSalesComponent;
  