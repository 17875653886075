import "./HeadLineComponent.css";
import SearchFormUserSiteComponent from "../SearchFormUserSiteComponent/SearchFormUserSiteComponent";
import { ROOT_AVATAR_API, ROOT_DOMAIN_API } from "../../Constant";
import { useState } from 'react';
import axios from 'axios';

function HeadLineComponent(props) {
  const [seed, setSeed] = useState(localStorage.getItem('avatar_seed'));

  function generateRandomUUID(){
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      // eslint-disable-next-line
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const randomizeAvatar = function(){
    let seed = generateRandomUUID();
    localStorage.setItem('avatar_seed', seed);
    setSeed(seed);
    
    (async () => {
      try {
          const resp = await axios.post(ROOT_DOMAIN_API+"/manage/v1/pros_user/prospection_manageAvatar",
            {seed: seed});
          const obj = await resp.data;
          if(obj.results!=null){
          }
      } catch (error) {
          console.error(error);
      }
      
    })()
  }

  return (
    <div className={"HeadLine FlexRow FlexBottom JustifyBetween " + props.childClass}>
        <div className="FlexRow">
          <div className="FlexCol MR20">
            <img alt={"Avatar"} className="AvatarIco" src={ROOT_AVATAR_API+(seed ?? "")+"&mouth=smile&clothing=graphicShirt&clothingGraphic=bear"}/>
            <button onClick={() => randomizeAvatar()} className="Button AvatarAction">
              <i className="fa-solid fa-dice"></i>
            </button>
          </div>
          <div>
            <h1 className="M0">{props.intro ? props.intro : "Hey " + (localStorage.getItem('pros_adm_name') ?? "/")},</h1>
            <h5 className="M0 MT5">{props.emoji ? <i className={props.emoji}></i> : ''}{props.title}</h5>
          </div>
        </div>
        <div className="FlexCol">
          <SearchFormUserSiteComponent></SearchFormUserSiteComponent>
        </div>
    </div>
  );
}

export default HeadLineComponent;
